import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { Button, message, Select, Table, Tabs } from 'antd';
import moment from 'moment-timezone';
import { DatePicker } from '~/components/form';
import { CurrencyFormatter, TIMEZONE } from './helper';

const { Option } = Select;
const { TabPane } = Tabs;

const entityName = 'Sales Target Report';

class SalesTargetReport extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesTargetReport: PropTypes.shape({
        stores_data: PropTypes.arrayOf(PropTypes.object),
        salesman_data: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      params: PropTypes.shape({
      }).isRequired,
      route: PropTypes.shape({
        prepareVariables: PropTypes.func.isRequired,
      }).isRequired
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const { match, viewer } = this.props;
    const { filterBy } = match.route.prepareVariables();
    const [ { filter: dateFilter }] = filterBy;
    const initDateRange = dateFilter.split(',').map(d => moment(d));
    const StoreData = get(viewer, 'salesTargetReport.stores_data', []);
    const SalesmanData = get(viewer, 'salesTargetReport.salesman_data', []);

    this.dateRange = initDateRange;
    this.fetchedDateRange = '';

    this.dateRangePickerRef = React.createRef();

    this.state = {
      activeKey: "Store",
      loading: false,
      locations: [],
      states: [],
      staff: [],
      filteredStoreData: StoreData,
      filteredSalesmanData: SalesmanData,
      currentDates: null,
    };
  }

  onDateChange = (dateRange) => {
    this.dateRange = dateRange;
  }

  onClickThisMonth = () => {
    const end = moment().subtract(1, 'days').endOf('day');
    const start = moment().startOf('month');
    this.dateRange = [start, end];

    if (this.dateRangePickerRef.current) {
      this.dateRangePickerRef.current.blur();
    }

    this.refetch(this.dateRange);
  }

  onCurrentDateChange = (currentDates) => {
    this.setState({currentDates});
  }

  onFilterChange = (locations = [], states = [], staff = []) => {
    const { viewer } = this.props;
    const StoreData = get(viewer, 'salesTargetReport.stores_data', []);
    const SalesmanData = get(viewer, 'salesTargetReport.salesman_data', []);

    const filteredSalesmanData = SalesmanData.filter(item =>
      (locations.length === 0 || locations.includes(item.branch_name)) &&
      (states.length === 0 || states.includes(item.state)) &&
      (staff.length === 0 || staff.includes(item.staff_name))
    );

    const filteredStoreData = StoreData.filter(item =>
      (locations.length === 0 || locations.includes(item.branch_name)) &&
      (states.length === 0 || states.includes(item.state))
    );

    this.setState({ filteredStoreData, filteredSalesmanData });
  }

  disableDate = (current) => {
    const { currentDates } = this.state;

    if ( currentDates ) {
      const from = currentDates[0];
      const to = currentDates[1];

      if (from) {
        const endOfMonth = from.clone().endOf('month');
        return current.isBefore(from) || current.isAfter(endOfMonth);
      } else if (to) {
        const startOfMonth = to.clone().startOf('month');
        return current.isBefore(startOfMonth) || current.isAfter(to);
      }
    }

    return false;
  };

  SalesmanColumns = () => {
    const titles = [...new Set(get(this.props.viewer, 'salesTargetReport.salesman_data', []).map(item => item.title))];

    return (
      [
        {
          title: 'Staff Name',
          dataIndex: 'staff_name',
          key: 'staff_name',
          sorter: (a, b) => a.staff_name.localeCompare(b.staff_name),
        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          sorter: (a, b) => a.title.localeCompare(b.title),
          filters: titles.map(title => ({
            text: title,
            value: title,
          })),
          onFilter: (value, record) => record.title === value,
        },
        {
          title: 'Sales',
          dataIndex: 'revenue',
          key: 'revenue',
          render: sales => (
            <div>
              <CurrencyFormatter amount={sales} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.revenue) - parseFloat(b.revenue),
          className: "ant-alert-warning"
        },
        {
          title: 'No. of Invoice',
          dataIndex: 'no_of_invoice',
          key: 'no_of_invoice',
          sorter: (a, b) => a.no_of_invoice.localeCompare(b.no_of_invoice),
          className: "ant-alert-warning"
        },
        {
          title: 'Average Ticket Price',
          dataIndex: 'average_ticket_price',
          key: 'average_ticket_price',
          render: average => (
            <div>
              <CurrencyFormatter amount={average} />
            </div>
          ),
          sorter: (a, b) => a.average_ticket_price.localeCompare(b.average_ticket_price),
          className: "ant-alert-warning"
        },
        {
          title: 'Branch Name',
          dataIndex: 'branch_name',
          key: 'branch_name',
          sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
        },
        {
          title: 'Date Started',
          dataIndex: 'start_date',
          key: 'start_date',
          sorter: (a, b) => a.start_date.localeCompare(b.start_date),
        },
        {
          title: 'Days Worked',
          dataIndex: 'days_worked',
          key: 'days_worked',
          sorter: (a, b) => parseFloat(a.days_worked) - parseFloat(b.days_worked),
        },
        {
          title: 'Daily Target',
          dataIndex: 'daily_target',
          key: 'daily_target',
          render: dailyTarget => (
            <div>
              <CurrencyFormatter amount={dailyTarget} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.daily_target) - parseFloat(b.daily_target),
        },
        {
          title: 'Daily Average',
          dataIndex: 'daily_average',
          key: 'daily_average',
          render: monthlyTarget => (
            <div>
              <CurrencyFormatter amount={monthlyTarget} />
            </div>
          ),
          sorter: (a, b) => parseFloat(a.daily_average) - parseFloat(b.daily_average),
        },
        {
          title: 'Percentage',
          dataIndex: 'pct',
          key: 'pct',
          render: text => typeof text === 'number' ? `${(parseFloat(text) * 100).toFixed(2)}%` : '0%',
          sorter: (a, b) => parseFloat(a.pct) - parseFloat(b.pct),
        },
        {
          title: 'Year_Month',
          dataIndex: 'year_month',
          key: 'year_month',
          sorter: (a, b) => a.year_month.localeCompare(b.year_month),
        },
        {
          title: 'State',
          dataIndex: 'state',
          key: 'state',
          sorter: (a, b) => a.state.localeCompare(b.state),
        },
        {
          title: 'Meet',
          dataIndex: 'meet',
          key: 'meet',
          sorter: (a, b) => parseFloat(a.meet) - parseFloat(b.meet),
        },
      ]
    )
  };

  StoreColumn = () => {
    const filteredTitles = [
      'Year_Month',
      'Branch Name',
      'State',
      'Daily Target',
      'Daily Average',
      'Sales',
      'Percentage',
      'Meet'
    ];

    return this.SalesmanColumns().filter(column => filteredTitles.includes(column.title));
  }

  refetch = (date) => {
    if (this.fetchedDateRange && this.fetchedDateRange === date) {
      return;
    }

    const dateRangeFilter = [moment(this.dateRange[0]).startOf('day').utc().format(), moment(this.dateRange[1]).endOf('day').utc().format()].join(",");

    const refetchVariables = {
      filterBy: [
        {
          field: "inserted_at",
          filter: dateRangeFilter,
          filterType: "text",
          type: "inRange",
        },
      ]
    };

    const loading = true;
    this.setState({loading});

    this.props.relay.refetch(
      refetchVariables,
      null,
      (error)=> {
        if (error) {
          const e = get(error, '[0].message', 'Shit happened');
          message.error(e);

          this.setState({filteredSalesmanData: [], filteredStoreData: []});
        } else {
          const { locations, states, staff } = this.state;
          this.onFilterChange(locations, states, staff);
        }

        this.fetchedDateRange = date;

        this.setState({
          loading: false,
        });

      },
      refetchVariables
    );
  }

  renderSelectStates = () => {
    const STATES = () => {
      const country = process.env.COUNTRY;

      if ( country === "NZ" ) {
        return ['NORTH ISLAND', 'SOUTH ISLAND'];
      }

      return ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
    }

    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={states => {
          this.setState({states});
          this.onFilterChange(this.state.locations, states, this.state.staff);
        }}
        value={this.state.states}
        placeholder="Select a state"
        style={{marginLeft: '20px', minWidth: '200px'}}
        disabled={this.state.locations.length>0}
      >
        {STATES().map(node => (
          <Option key={node} value={node}>{node}</Option>
        ))}
      </Select>
    )
  }

  renderSelectStores = () => {
    const StoreData = get(this.props.viewer, 'salesTargetReport.stores_data', []);
    const stores = [...new Set(StoreData.map(item => item.branch_name))].sort((a, b) => a.localeCompare(b));

    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={locations => {
          this.setState({locations});
          this.onFilterChange(locations, this.state.states, this.state.staff);
        }}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={this.state.locations}
        placeholder="Select a store"
        style={{marginLeft: '20px', minWidth: '200px'}}
        disabled={this.state.states.length>0}
      >
        {stores.map((node) => {
          return <Option key={node} value={node}>{node}</Option>
        })}
      </Select>
    )
  }

  renderSelectStaff = () => {
    const SalesmanData = get(this.props.viewer, 'salesTargetReport.salesman_data', []);
    const staffs = [...new Set(SalesmanData.map(item => item.staff_name))].sort((a, b) => a.localeCompare(b));;

    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={staff => {
          this.setState({staff});
          this.onFilterChange(this.state.locations, this.state.states, staff);
        }}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={this.state.staff}
        placeholder="Select a staff"
        style={{marginLeft: '20px', minWidth: '200px'}}
      >
        {staffs.map((node) => {
          return <Option key={node} value={node}>{node}</Option>
        })}
      </Select>
    )
  }

  render() {
    const { activeKey, filteredSalesmanData, filteredStoreData,loading } = this.state;
    const rowStyles = {
      1: 'ant-alert-success',
      0: 'ant-alert-error',
    };

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        <h1>{entityName}</h1>

        <DatePicker.RangePicker
          ref={this.dateRangePickerRef}
          onChange={this.onDateChange}
          onCalendarChange={this.onCurrentDateChange}
          disabledDate={this.disableDate}
          value={this.dateRange}
          onOpenChange={open => {
            // if popup panel is closing
            if (!open) {
              this.refetch(this.dateRange);
              this.setState( {currentDates: null} );
            }
          }}
          renderExtraFooter={() => (
            <Button onClick={this.onClickThisMonth}>
              This Month
            </Button>
          )}
          timezone={TIMEZONE}
        />

        {this.renderSelectStates()}
        {this.renderSelectStores()}

        {activeKey ==='Salesman' && this.renderSelectStaff()}

        <Tabs activeKey={activeKey} onChange={(key) => { this.setState({ activeKey: key }); }}>
          <TabPane tab="Store" key="Store">
            <Table
              dataSource={filteredStoreData.map((item, index) => ({ ...item, key: index }))}
              columns={this.StoreColumn()}
              loading={loading}
              pagination={false}
              scroll={{ x: true }}
              rowClassName={(record) => {
                return rowStyles[record.meet]
              }}
              footer={() => (
                <div style={{ textAlign: 'right', paddingRight: '16px' }}>
                  Total rows: {filteredStoreData.length}
                </div>
              )}
            />
          </TabPane>

          <TabPane tab="Salesman" key="Salesman">
            <Table
              dataSource={filteredSalesmanData.map((item, index) => ({ ...item, key: index }))}
              columns={this.SalesmanColumns()}
              loading={loading}
              pagination={{ position: "both", pageSize: 100 }}
              scroll={{ x: true }}
              rowClassName={(record) => {
                return rowStyles[record.meet]
              }}
              size="small"
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default createRefetchContainer(
    SalesTargetReport, {
    viewer: graphql`
    fragment SalesTargetReport_viewer on Admin {
      salesTargetReport(filterBy: $filterBy)
    }
  `,
  },
  graphql`
    query SalesTargetReportRefetchQuery($filterBy: [FilterBy]) {
      viewer {
        ...SalesTargetReport_viewer
      }
    }
  `,
);
