/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ByMinMaxReport_reportFragment_viewer$ref = any;
type ByMinMaxReport_selectFragment_viewer$ref = any;
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type OrderBy = {|
  direction: string,
  field: string,
|};
export type routes_ByMinMaxReport_QueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>,
  orderBy?: ?OrderBy,
|};
export type routes_ByMinMaxReport_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ByMinMaxReport_selectFragment_viewer$ref & ByMinMaxReport_reportFragment_viewer$ref
  |}
|};
export type routes_ByMinMaxReport_Query = {|
  variables: routes_ByMinMaxReport_QueryVariables,
  response: routes_ByMinMaxReport_QueryResponse,
|};
*/


/*
query routes_ByMinMaxReport_Query(
  $filterBy: [FilterBy]
  $orderBy: OrderBy
) {
  viewer {
    ...ByMinMaxReport_selectFragment_viewer
    ...ByMinMaxReport_reportFragment_viewer
    id
  }
}

fragment ByMinMaxReport_reportFragment_viewer on Admin {
  minMaxReport(count: 5000, filterBy: $filterBy, orderBy: $orderBy)
  id
}

fragment ByMinMaxReport_selectFragment_viewer on Admin {
  brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
  stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
        state
        branchCode
      }
    }
  }
  suppliers
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "asc",
      "field": "name"
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_ByMinMaxReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ByMinMaxReport_selectFragment_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ByMinMaxReport_reportFragment_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_ByMinMaxReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BrandConnection",
            "kind": "LinkedField",
            "name": "brands",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Brand",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "brands(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "StoreConnection",
            "kind": "LinkedField",
            "name": "stores",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "branchCode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "stores(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "suppliers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "count",
                "value": 5000
              },
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy"
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              }
            ],
            "kind": "ScalarField",
            "name": "minMaxReport",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e502779041fe8e46fe8137103cf8acc2",
    "id": null,
    "metadata": {},
    "name": "routes_ByMinMaxReport_Query",
    "operationKind": "query",
    "text": "query routes_ByMinMaxReport_Query(\n  $filterBy: [FilterBy]\n  $orderBy: OrderBy\n) {\n  viewer {\n    ...ByMinMaxReport_selectFragment_viewer\n    ...ByMinMaxReport_reportFragment_viewer\n    id\n  }\n}\n\nfragment ByMinMaxReport_reportFragment_viewer on Admin {\n  minMaxReport(count: 5000, filterBy: $filterBy, orderBy: $orderBy)\n  id\n}\n\nfragment ByMinMaxReport_selectFragment_viewer on Admin {\n  brands(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  stores(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n        state\n        branchCode\n      }\n    }\n  }\n  suppliers\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '540289feedf4cccd6287e82c6b6a861a';

module.exports = node;
